import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams
} from "react-router-dom";
import axios from 'axios';


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:urlKey" element={<ShortUrl />} />
      </Routes>
    </Router>
  );
}

function ShortUrl() {
  let { urlKey } = useParams();// r5g8oh
  
  axios.get('https://api.hoppah.io/urls/target?key=' + urlKey)
  .then(function (response) {
    // handle success
    console.log(response.data.data.target_url);
    window.location.replace(response.data.data.target_url);

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })


  return (
    null
  );
}

function Home() {
  return (
    null
  );
}
